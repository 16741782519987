import {
  GridItemResponsiveType,
} from "react-schematic";
/**
 * Grid items data
 */
type GridItemsData = GridItemResponsiveType & {
  text: string; title: string; id: string; name: string; pathname: string;
};
export const gridItemsData: GridItemsData[] = [
  {
    id: "buy-tickets",
    title: "BuyTickets",  
    text: "READ",
    name: "blog",
    pathname: "/seal/"
   // The gridArea useful behaviour moves a grid items position in the list 
   // based upon the view size and grid items setting sm/md/lg... etc.
   // gridArea: { md: "2/2/span 1/span 1"}
  },
  {
    id: "draw-winner-ticket",
    title: "DrawWinnerTicket", 
    text: "READ",
    name: "blog",
    pathname: "/elegal/"
    // gridArea: { md: "2/2/span 1/span 1"} 
      
  },
  {
    id: "refund-all",
    title: "RefundAll", 
    text: "READ",
    name: "blog",
    pathname: "/contract-interact/"
  },
  {
    id: "withdraw-commission",
    title: "WithdrawCommission", 
    text: "READ",
    name: "blog",
    pathname: "/memetoday-cafe/"
  },
  {
    id: "Withdraw-winnings",
    title: "WithdrawWinnings", 
    text: "READ",
    name: "blog",
    pathname: "/raffle/"
   // gridArea: "1/1/span 1/span 1"
  },
  {
    id: "restart-draw",
    title: "restartDraw", 
    text: "READ",
    name: "blog",
    pathname: "/voting-app/"
  },
  {
    id: "current-winning-reward",
    title: "CurrentWinningReward", 
    text: "WRITE",
    name: "blog",
    pathname: "/predict/"
   // gridArea: "1/1/span 1/span 1"
  },
  {
    id: "is-winner",
    title: "IsWinner", 
    text: "WRITE",
    name: "blog",
    pathname: "/cat-meme/"
  },
  {
    id: "remaining-tickets",
    title: "RemainingTickets", 
    text: "WRITE",
    name: "blog",
    pathname: "/cat-meme/"
  },
  {
    id: "check-winnings-amount",
    title: "CheckWinningsAmount", 
    text: "WRITE",
    name: "blog",
    pathname: "/cat-meme/"
  },
  {
    id: "duration",
    title: "duration", 
    text: "WRITE",
    name: "blog",
    pathname: "/cat-meme/"
  },
  {
    id: "expiration",
    title: "expiration", 
    text: "WRITE",
    name: "blog",
    pathname: "/cat-meme/"
  },
  {
    id: "get-tickets",
    title: "getTickets", 
    text: "WRITE",
    name: "blog",
    pathname: "/cat-meme/"
  },
  {
    id: "get-winnings-for-address",
    title: "getWinningsForAddress", 
    text: "WRITE",
    name: "blog",
    pathname: "/cat-meme/"
  },
  {
    id: "last-winner",
    title: "lastWinner", 
    text: "WRITE",
    name: "blog",
    pathname: "/cat-meme/"
  },
  {
    id: "last-winner-amount",
    title: "lastWinnerAmount", 
    text: "WRITE",
    name: "blog",
    pathname: "/cat-meme/"
  },
  {
    id: "lottery-operator",
    title: "lotteryOperator", 
    text: "WRITE",
    name: "blog",
    pathname: "/cat-meme/"
  },
  {
    id: "max-tickets",
    title: "maxTickets", 
    text: "WRITE",
    name: "blog",
    pathname: "/cat-meme/"
  },
  {
    id: "operator-total-commission",
    title: "operatorTotalCommission", 
    text: "WRITE",
    name: "blog",
    pathname: "/cat-meme/"
  },
  {
    id: "ticket-commission",
    title: "ticketCommission", 
    text: "WRITE",
    name: "blog",
    pathname: "/cat-meme/"
  },
  {
    id: "tickets",
    title: "tickets", 
    text: "WRITE",
    name: "blog",
    pathname: "/cat-meme/"
  },
  {
    id: "winnings",
    title: "winnings", 
    text: "WRITE",
    name: "blog",
    pathname: "/cat-meme/"
  }
];


